import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import StyledDropzone from './components/NotebookConverter/components/Dropzone';
import quartoParams from './quarto_params.json';
import ProtectedButton from './components/login/ProtectedAction';

// Required for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PDFForm({ file, disabled, state, handleDrop, handleDropRejected, handleParamChange, handleConvert, errorMessage }) {
    const [collapsedSections, setCollapsedSections] = useState(
        Object.fromEntries(quartoParams.map(section => [section.section_name, section.collapsed]))
    );
    const [formValues, setFormValues] = useState(
        Object.fromEntries(
            quartoParams.flatMap(section =>
                section.params.map(param => [param.name, param.default])
            )
        )
    );

    const toggleSection = (sectionName) => {
        setCollapsedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };

    const handleInputChange = (paramName, value) => {
        setFormValues(prev => ({
            ...prev,
            [paramName]: value
        }));
        handleParamChange(paramName, value);
    };

    return (
        <div className="w-full max-w-[300px] mt-2">
            <div>
                <StyledDropzone
                    onDrop={handleDrop}
                    onDropRejected={handleDropRejected}
                />
                <div className="mt-2 text-gray-700">
                    Selected file: {file ? file.name : 'No file'}
                </div>
            </div>
            <div className="mt-5">
                {quartoParams.map((section) => (
                    <div key={section.section_name} className={`w-full p-4 border rounded-lg shadow-sm mb-5`}>
                        <div
                            className="flex items-center justify-between cursor-pointer h-4"
                            onClick={() => toggleSection(section.section_name)}
                        >
                            <p className="font-medium flex-1">{section.section_name}</p>
                            <span className="text-lg font-bold">
                                {collapsedSections[section.section_name] ? '▼' : '▲'}
                            </span>
                        </div>

                        {!collapsedSections[section.section_name] && (
                            <div className="mt-4 space-y-4">
                                {section.params.map((param) => (
                                    <div key={param.name} className="mb-4">
                                        <label className="block mb-1">
                                            <span className="mr-2">{param.label}</span>
                                            {param.type === 'boolean' && (
                                                <input
                                                    type="checkbox"
                                                    className="ml-2.5 border border-gray-300 rounded"
                                                    checked={formValues[param.name] || false}
                                                    onChange={(e) => handleInputChange(param.name, e.target.checked)}
                                                />
                                            )}
                                            {param.type === 'number' && (
                                                <input
                                                    type="number"
                                                    className="w-full p-1 border border-gray-300 rounded bg-gray-50 focus:outline-none focus:border-gray-400"
                                                    value={formValues[param.name] || ''}
                                                    onChange={(e) => {
                                                        const value = parseInt(e.target.value);
                                                        if (!isNaN(value)) {
                                                            handleInputChange(param.name, value);
                                                        }
                                                    }}
                                                />
                                            )}
                                            {param.type === 'string' && (
                                                <input
                                                    type="text"
                                                    className="w-full p-1 border border-gray-300 rounded bg-gray-50 focus:outline-none focus:border-gray-400"
                                                    value={formValues[param.name] || ''}
                                                    onChange={(e) => handleInputChange(param.name, e.target.value)}
                                                />
                                            )}
                                        </label>
                                        <div className="text-sm text-gray-500 mt-0.5">
                                            {param.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
                <div className="flex justify-center">
                    <div
                        className={`Button flex justify-center w-4/5 mt-2 mb-8 ${disabled ? "Disabled" : ""}`}
                        onClick={handleConvert}
                    >
                        <span>
                            {state === 'uploading' ? "Converting..." : "Convert to PDF"}
                        </span>
                    </div>
                </div>
                <div className="text-red-500 text-sm mt-4 text-center" ref={errorMessage}></div>
            </div>
        </div>
    );
}

function Advanced() {
    const [file, setFile] = useState(null);
    const [state, setState] = useState('start');
    const [disabled, setDisabled] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [formParams, setFormParams] = useState({});
    const errorMessage = useRef(null);

    useEffect(() => {
        setDisabled(!file);
    }, [file]);

    const handleDrop = (files) => {
        let file = files[0];
        setFile(file);
        setPdfUrl(null);
        setCurrentPage(1);
    };

    const handleDropRejected = () => {
        alert("File type is not allowed. You can only upload .ipynb files");
    };

    const handleParamChange = (paramName, value) => {
        setFormParams(prev => ({
            ...prev,
            [paramName]: value
        }));
    };

    const handleDownload = () => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = file.name.replace('.ipynb', '.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleConvert = async () => {
        if (disabled) {
            return;
        }

        setDisabled(true);
        setState('uploading');
        errorMessage.current.innerText = "";
        const formData = new FormData();
        formData.append("file", file, file.name);

        const params = new URLSearchParams({
            'converter-type': 'quarto-pdf',
            'advanced': 'true'
        });

        Object.entries(formParams).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
                params.append(key, value);
            }
        });

        let url = `${process.env.REACT_APP_API_URL}/notebooks/upload?${params.toString()}`;

        try {
            const uploadResponse = await fetch(url, {
                method: "POST",
                body: formData
            });

            if (uploadResponse.status !== 202) {
                throw new Error(`Upload failed with status ${uploadResponse.status}`);
            }

            const uploadResult = await uploadResponse.json();
            const jobId = uploadResult.job_id;

            while (true) {
                const statusResponse = await fetch(`${process.env.REACT_APP_API_URL}/notebooks/result/${jobId}`);

                if (statusResponse.status === 200) {
                    const result = await statusResponse.json();
                    const uuid = result.result;

                    const downloadResponse = await fetch(`${process.env.REACT_APP_API_URL}/download/${uuid}/`);
                    if (downloadResponse.status !== 200) {
                        throw new Error(`Download failed with status ${downloadResponse.status}`);
                    }

                    const blob = await downloadResponse.blob();
                    const pdfUrl = URL.createObjectURL(blob);
                    setPdfUrl(pdfUrl);
                    break;
                } else if (statusResponse.status === 202) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    throw new Error(`Job failed with status ${statusResponse.status}`);
                }
            }
        } catch (err) {
            let errMessage = `Error: ${err.message}`;
            errorMessage.current.innerText = errMessage;
        } finally {
            setState('start');
            setDisabled(false);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setCurrentPage(1);
    };

    const goToPrevPage = () => {
        setCurrentPage(page => Math.max(page - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage(page => Math.min(page + 1, numPages));
    };

    return (
        <div className="container mx-auto px-10">
            <h1 className="text-center mb-4 font-bold">Advanced Jupyter Notebook to PDF converter</h1>
            <div data-testid='converter'>
                <div className="flex flex-col md:flex-row gap-8">
                    {/* Left side - Form */}
                    <div className="w-full md:w-1/3 md:sticky md:top-0">
                        <PDFForm
                            file={file}
                            disabled={disabled}
                            state={state}
                            handleDrop={handleDrop}
                            handleDropRejected={handleDropRejected}
                            handleParamChange={handleParamChange}
                            handleConvert={handleConvert}
                            errorMessage={errorMessage}
                        />
                    </div>

                    {/* Right side - PDF Preview */}
                    <div className="w-full md:w-2/3 mt-2">
                        {pdfUrl ? (
                            <>
                                <div className="mb-4 flex flex-wrap gap-2.5 items-center justify-center">
                                    <button
                                        onClick={goToPrevPage}
                                        disabled={currentPage <= 1}
                                        className="px-2.5 py-1 ButtonSecondary Small"
                                    >
                                        Previous
                                    </button>
                                    <span>Page {currentPage} of {numPages}</span>
                                    <button
                                        onClick={goToNextPage}
                                        disabled={currentPage >= numPages}
                                        className="px-2.5 py-1 ButtonSecondary Small"
                                    >
                                        Next
                                    </button>
                                    <ProtectedButton
                                        onClick={handleDownload}
                                        className="px-2.5 py-1 Button Small"
                                    >
                                        Download PDF
                                    </ProtectedButton>
                                </div>
                                <div className="border-2 border-gray-300 p-[1px] bg-gray-100 rounded w-full min-h-[600px] flex items-center justify-center mb-4">
                                    <Document
                                        file={pdfUrl}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            pageNumber={currentPage}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    </Document>
                                </div>
                            </>
                        ) : (
                            <div className="border-2 border-gray-300 p-8 bg-gray-100 rounded-lg text-center text-gray-600 min-h-[600px] flex items-center justify-center">
                                PDF preview will appear here
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Advanced;
