import React from 'react';
import marketing from '../marketing.json';

const Marketing = () => {
    return (
        <div className="bg-gray-50 py-12 px-4 sm:px-8">
            <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
                Ploomber Cloud
            </h1>
            <p className="text-lg text-gray-600 mb-8 text-center">
                A platform to deploy data applications.
            </p>
            <div className="max-w-7xl mx-auto px-4 sm:px-8 lg:px-16">
                <div className="space-y-12 sm:space-y-16">
                    {marketing.map((section, index) => (
                        <div key={index} className="flex flex-col sm:flex-row items-start gap-8 sm:gap-12">
                            <div className="w-full sm:w-1/3">
                                <img
                                    src={`/images/marketing/${section.image}`}
                                    alt={section.title}
                                    className="w-full rounded-lg shadow-lg"
                                />
                            </div>
                            <div className="w-full sm:w-2/3">
                                <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 text-center sm:text-left">
                                    {section.title}
                                </h2>
                                <p className="text-base sm:text-lg text-gray-600 text-center sm:text-left">
                                    {section.description}
                                </p>
                                <div className="mt-8 flex sm:justify-start justify-center">
                                    <a
                                        href={`https://www.platform.ploomber.io/register${section.url_args}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none' }}
                                        className="Button"
                                    >
                                        Sign up
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Marketing;
