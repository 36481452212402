import React, { useEffect, useState } from "react";
import NotebookConverter from "./components/NotebookConverter";
import posthog from 'posthog-js';
import { Footer } from "./components/templates";
import { BrowserRouter, Route, Routes, useRouteError } from "react-router-dom";
import './index.css';
import NotFound from "./components/NotFound";
import { AuthProvider } from "./components/login/AuthProvider";
import Advanced from "./Advanced";
import Marketing from "./components/Marketing";

export const CONVERTERS_MAP = {
  "pdf": { route: "nbconvert-pdf", displayName: "PDF", label: "PDF" },
  "latex": { route: "nbconvert-latex", displayName: "LaTeX", label: "LaTeX" },
  "html": { route: "nbconvert-html", displayName: "HTML", label: "HTML for web viewing" },
  "py": { route: "nbconvert-py", displayName: "Python", label: "Python script" },
  "rst": { route: "nbconvert-rst", displayName: "reStructuredText", label: "reStructuredText for documentation" }
};

export const CONVERTERS = Object.keys(CONVERTERS_MAP)


function ErrorBoundary() {
  const error = useRouteError();
  return (
    <p className="ErrorMessage"> Error: {error.message} </p>
  );
}

const TopBanner = () => {
  const bannerOptions = [
    {
      text: "Deploy Streamlit apps for free on ",
      link: "Ploomber Cloud!",
      url: "https://platform.ploomber.io/register/?utm_medium=convert&utm_source=streamlit"
    },
    {
      text: "Deploy Shiny apps for free on ",
      link: "Ploomber Cloud!",
      url: "https://platform.ploomber.io/register/?utm_medium=convert&utm_source=shiny-r"
    },
    {
      text: "Deploy Dash apps for free on ",
      link: "Ploomber Cloud!",
      url: "https://platform.ploomber.io/register/?utm_medium=convert&utm_source=dash"
    },
    {
      text: "Try our new Streamlit ",
      link: "AI Editor!",
      url: "https://editor.ploomber.io/?utm_medium=convert&utm_source=editor"
    }
  ];

  const [bannerText, setBannerText] = useState(null);
  const [isChanging, setIsChanging] = useState(false);


  useEffect(() => {
    const updateBanner = () => {
      setIsChanging(true);

      setTimeout(() => {
        let newOption;
        do {
          newOption = bannerOptions[Math.floor(Math.random() * bannerOptions.length)];
        } while (bannerText && newOption.text + newOption.link === bannerText.text + bannerText.link);

        setBannerText(newOption);
        setIsChanging(false);
      }, 500);
    };

    // Initial banner set without transition
    setBannerText(bannerOptions[0]);

    // Start transitions after 5 seconds
    const timeout = setTimeout(() => {
      updateBanner();
      const interval = setInterval(updateBanner, 5000);
      return () => clearInterval(interval);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="sticky top-0 text-center bg-gray-100 py-4 z-50">
      <p className={`transition-opacity duration-500 ease-in-out ${isChanging ? 'opacity-0' : 'opacity-100'}`}>
        {bannerText && (
          <>
            {bannerText.text}
            <a
              href={bannerText.url}
              target="_blank"
              rel="noopener noreferrer"
              className="font-extrabold"
            >
              {bannerText.link}
            </a>
          </>
        )}
      </p>
    </div>
  );
}

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'dev') {
      posthog.init(process.env.REACT_APP_PH_API_KEY, { api_host: 'https://app.posthog.com', autocapture: true });
    }
  }, []);

  let dispatchPHEvent = (event, properties = {}) => {
    if (process.env.REACT_APP_ENV === 'dev') {
      return
    }

    let distinctId = posthog.persistence.props.distinct_id;
    let url = `https://app.posthog.com/capture`;

    let request = {
      "api_key": process.env.REACT_APP_PH_API_KEY,
      "event": event,
      "properties": {
        "distinct_id": distinctId,
        ...properties
      },
    }

    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(request)
    })
      .then(response => response.json())
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <AuthProvider>
      <TopBanner />
      <div className="App">
        <BrowserRouter>
          <main>
            <Routes>
              <Route
                path="/"
                element={<NotebookConverter dispatchPHEvent={dispatchPHEvent} converter={CONVERTERS[0]} />}
                errorElement={<ErrorBoundary />}
              />
              {CONVERTERS.map(format => (
                <Route
                  path={`/${format}`}
                  element={<NotebookConverter dispatchPHEvent={dispatchPHEvent} converter={format} />}
                  errorElement={<ErrorBoundary />}
                />
              ))}
              <Route path="/pdf-advanced" element={<Advanced />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </BrowserRouter>
        <Marketing />
      </div>
    </AuthProvider>
  );
}

export default App;
